<template>
  <div style="background: #fff; padding-bottom: 40px; width: 100%">
    <!-- 单选框区域 -->
    <el-descriptions direction="vertical" :column="1" border style="width: 100%">
      <el-descriptions-item label="是否中标" :span="1">
        <el-radio-group
          :value="formData.isWin"
          style="margin-bottom: 20px; padding: 0 20px"
          @input="onRadioChange"
          :disabled="!canWrite"
        >
          <el-radio label="loser">未中标</el-radio>
          <el-radio label="winner"> 已中标 </el-radio>
        </el-radio-group>
      </el-descriptions-item>
      <el-descriptions-item label="中标金额类型" :span="1">
        <el-radio-group
          v-model="formData.bidWinningAmountType"
          style="margin-bottom: 20px; padding: 0 20px"
          :disabled="!canWrite"
        >
          <el-radio :label="1">固定总价</el-radio>
          <el-radio :label="2"> 固定单价 </el-radio>
        </el-radio-group>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      direction="vertical"
      :column="1"
      border
      style="width: 100%"
      v-if="formData.bidWinningAmountType == 1"
    >
      <el-descriptions-item :span="1">
        <template slot="label"> 中标金额 </template>
        <el-input
          style="width: 250px"
          v-model="formData.bidWinningAmount"
          placeholder="请输入中标金额"
          @blur="onAmout(formData, 'bidWinningAmount')"
          type="number"
          class="numrule"
          :disabled="!canWrite"
        ></el-input>
      </el-descriptions-item>
    </el-descriptions>

    <el-table
      v-if="formData.bidWinningAmountType == 2"
      border
      :data="formData.bidUnitPriceReq"
      tooltip-effect="dark"
      empty-text="请添加单价内容"
      max-height="400"
    >
      <el-table-column label="服务内容" align="center" :show-overflow-tooltip="false">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.serviceContent"
            type="textarea"
            :disabled="!canWrite"
            :autosize="{ minRows: 1, maxRows: 999 }"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="价格" width="200" align="center" :show-overflow-tooltip="false">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.price"
            placeholder="请输入价格"
            @blur="onAmout(scope.row, 'price')"
            type="number"
            class="numrule"
            :disabled="!canWrite"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="计价单位" align="center" :show-overflow-tooltip="false">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.unit"
            type="textarea"
            :disabled="!canWrite"
            :autosize="{ minRows: 1, maxRows: 999 }"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column align="center" width="80" v-if="canWrite">
        <template slot="header">
          <i class="iconfont iconadd-circle" @click="addUnitPrice"></i>
        </template>
        <template slot-scope="scope">
          <i class="iconfont iconerror" @click="deleteUnitPrice(scope)"></i>
        </template>
      </el-table-column>
    </el-table>
    <!-- 已中标附件上传区域 -->
    <!-- <UploadList
      style="min-height: 500px"
      v-model="uploadData"
      fileCode="user_fj"
      v-show="formData.isWin == 'winner'"
      :staffId="formData.fkId"
      :disabled="!canWrite"
    ></UploadList> -->
    <!-- 未中标 -->
    <el-form
      ref="form"
      v-if="formData.isWin == 'loser' && formData.bidAnalysisResp"
      :model="formData.bidAnalysisResp"
      :rules="rules"
    >
      <!-- 必填内容 -->
      <el-descriptions direction="vertical" :column="2" border style="width: 100%">
        <el-descriptions-item :span="2">
          <template slot="label">
            <span style="color: red">*</span>
            失败原因
          </template>
          <el-form-item prop="failureReason" ref="failureReason" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.failureReason"
              type="textarea"
              placeholder="请输入失败原因"
              :disabled="!canWrite"
              :autosize="{ minRows: 2, maxRows: 999 }"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label">
            <span style="color: red">*</span>
            中标企业
          </template>
          <el-form-item prop="competitor" ref="competitor" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.competitor"
              placeholder="请输入中标企业"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <!-- <el-descriptions-item :span="1">
          <template slot="label">
            <span style="color: red">*</span>
            中标金额
          </template>
          <el-form-item prop="bidAmount" ref="bidAmount" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.bidAmount"
              placeholder="请输入中标金额"
              @blur="blurInput('bidAmount')"
              type="number"
              class="numrule"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item> -->
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            对方优势
          </template>
          <el-form-item prop="competitorAdvantage" ref="competitorAdvantage" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.competitorAdvantage"
              type="textarea"
              placeholder="请输入对方优势"
              :disabled="!canWrite"
              :autosize="{ minRows: 2, maxRows: 999 }"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            对方弱点
          </template>
          <el-form-item prop="competitorWeakness" ref="competitorWeakness" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.competitorWeakness"
              placeholder="请输入对方弱点"
              :disabled="!canWrite"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 999 }"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            我们的报价
          </template>
          <el-form-item prop="ourPrice" ref="ourPrice" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.ourPrice"
              placeholder="请输入我们的报价"
              @blur="blurInput('ourPrice')"
              type="number"
              class="numrule"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            对方的报价
          </template>
          <el-form-item prop="competitorPrice" ref="competitorPrice" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.competitorPrice"
              placeholder="请输入对方的报价"
              @blur="blurInput('competitorPrice')"
              type="number"
              class="numrule"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            服务内容和技术方案
          </template>
          <el-form-item prop="serviceContent" ref="serviceContent" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.serviceContent"
              placeholder="请输入服务内容和技术方案"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 999 }"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            未达成的指标
          </template>
          <el-form-item prop="unmetIndicators" ref="unmetIndicators" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.unmetIndicators"
              placeholder="请输入未达成的指标"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 999 }"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <div class="result_title">中标结果文件</div>
    <UploadList
      style="min-height: 500px; margin-top: 10px"
      v-model="uploadData"
      fileCode="user_fj"
      :staffId="formData.fkId"
      :disabled="!canWrite"
    ></UploadList>
  </div>
</template>

<script>
import { getInstanceByDom } from 'echarts'
export default {
  name: 'WinResult',
  components: {
    UploadList: () => import('@/components/upload/ContractList.vue'),
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //当前页面输入框输入状态
    editType: {
      type: String,
      default: '',
    },
  },
  watch: {
    detailData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal.fkId) {
          const info = newVal.deepClone()
          this.formData = info
          this.formData.bidAnalysisResp = info.bidAnalysisResp || {}
          this.formData.bidUnitPriceDeleteList = info.bidUnitPriceDeleteList || []
          this.formData.bidUnitPriceReq = info.bidUnitPriceReq || []
          // 将详情中的附件数据传入uploadData
          this.getDetailFileList(info)
        } else {
          this.formData = {
            isWin: '',
            fkId: '',
            bidUnitPriceReq: [],
            bidWinningAmountType: null,
            bidWinningAmount: 0,
            bidUnitPriceDeleteList: [],
            bidAnalysisResp: {
              failureReason: null,
              competitor: null,
              competitorAdvantage: null,
              competitorWeakness: null,
              ourPrice: null,
              competitorPrice: null,
              serviceContent: null,
              unmetIndicators: null,
            },
          }
        }
      },
    },
  },
  computed: {
    canWrite() {
      switch (this.editType) {
        case 'add':
          return true
        case 'edit':
          return true
        case 'detail':
          return false
        case 'bidEdit':
          return false
        default:
          return false
      }
    },
  },
  data() {
    return {
      formData: {},
      uploadData: [],
      rules: {
        failureReason: [{ required: true, message: '请输入失败原因', trigger: 'blur' }],
        competitor: [{ required: true, message: '请输入中标企业', trigger: 'blur' }],
        bidAmount: [{ required: true, message: '请输入中标金额', trigger: 'blur' }],
      },
    }
  },
  methods: {
    deleteUnitPrice(scope) {
      this.formData.bidUnitPriceReq.splice(scope.$index, 1)
      if (scope.row.id) {
        this.formData.bidUnitPriceDeleteList.push(scope.row.id)
      }
    },
    addUnitPrice() {
      this.formData.bidUnitPriceReq.push({
        serviceContent: null,
        price: null,
        unit: null,
        bidManagementId: this.formData.fkId ? this.formData.fkId : null,
      })
    },
    onAmout(row, e) {
      row[e] = Number(row[e]).toFixed(2)
    },
    blurInput(e) {
      this.formData.bidAnalysisResp[e] = Number(this.formData.bidAnalysisResp[e]).toFixed(2)
    },
    onRadioChange(e) {
      this.formData.isWin = e
      // if (this.uploadData.length > 0) {
      //   this.uploadData = []
      // }
    },
    getDetailFileList(newVal) {
      if (newVal.fileList && newVal.fileList.length > 0) {
        let detailFileList = newVal.fileList.map(item => {
          return {
            attachmentName: item.fileName,
            attachmentPath: item.filePath,
            id: item.id,
            remark: item.remark,
            type: item.type,
            staffId: newVal.fkId,
            bidType: item.bidType,
          }
        })
        this.uploadData = [...detailFileList]
      }
    },
    verifySave() {
      let show = true
      if (this.formData.isWin == 'loser') {
        this.$refs.form.validate(valid => {
          if (valid) {
            show = true
          } else {
            show = false
          }
        })
      } else {
        show = true
      }
      return show
    },
    save() {
      // if (this.formData.isWin == 'winner' && this.uploadData.length == 0) {
      //   this.$message.warning('请上传附件')
      //   return 'warning'
      // }
      //过滤出被删除的附件数组
      let deleteArr = (this.detailData.fileList || [])
        .filter(
          item =>
            !this.uploadData
              .filter(x => x.id)
              .map(y => y.id)
              .includes(item.id)
        )
        .map(item => item.id)
      let fileList = this.uploadData.map(item => {
        return {
          fkId: item.staffId,
          fileName: item.attachmentName,
          filePath: item.attachmentPath,
          id: item.id,
          remark: item.remark,
          type: 'ZBFJ',
          bidType: 'bidFile',
        }
      })
      this.formData.fileList = fileList
      this.formData.deleteFileList = deleteArr
      return this.formData
    },
  },
}
</script>

<style lang="scss" scoped>
.result_title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  padding: 0 5px;
}
/deep/.el-descriptions .el-descriptions-item__cell {
  padding-bottom: 0px;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
